import ShippingMode from '@/components/ShippingMode/ShippingMode.vue'
import CertifiedSmsModal from '../CertifiedSmsModal/CertifiedSmsModal.vue'
import VueTagsInput from '@johmun/vue-tags-input'
import HelpText from '@/components/Layout/HelpText/HelpText.vue'

export default {
  name: 'AdvancedOptions',
  components: {
    ShippingMode,
    CertifiedSmsModal,
    VueTagsInput,
    HelpText,
  },
  props: ['campaign', 'options', 'preview'],
  data: function () {
    return {
      tag: '',
    }
  },
  methods: { },
  computed: { },
}
