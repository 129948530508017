export const MESSAGE_INTERACTIVE_HEADER_BUTTON_TYPE = [
  { id: 1, name: 'Imagen' },
  { id: 2, name: 'Texto' },
  { id: 3, name: 'Video' },
  { id: 4, name: 'Documento' },
]

export const MESSAGE_INTERACTIVE_HEADER_LIST_TYPE = [
  { id: 2, name: 'Texto' },
]

export const MESSAGE_INTERACTIVE_HEADER = {
  IMAGE: 1,
  TEXT: 2,
  VIDEO: 3,
  DOCUMENT: 4,
}
