import EventBus from '@/util/EventBus.js'
export default {
  name: 'BalanceAlert',
  data: function () {
    return {
      balanceAlert: false,
      balanceAlertScheduled: false,
    }
  },
  methods: {
    saveAndRedirect () {
      this.$emit('saveAndRedirect')
    },

    scheduled () {
      this.$emit('scheduled')
    },
  },
  mounted () {
    const that = this
    EventBus.$on('balanceAlertScheduled', function () {
      that.balanceAlertScheduled = true
    })
    EventBus.$on('balanceAlert', function () {
      that.balanceAlert = true
    })
  },
}
