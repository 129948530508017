import $t from '../../util/Translator'
import { MESSAGE_INTERACTIVE_HEADER_BUTTON_TYPE } from './enums/MessageInteractiveHeaderType'
import { MESSAGE_INTERACTIVE_TYPE } from './enums/MessageInteractiveType'

export default class MessageInteractive {
  name
  type
  header
  body
  footer
  action
  characters
  maxCharacters
  chunks

  constructor () {
    this.name = ''
    this.type = MESSAGE_INTERACTIVE_TYPE[0].id
    this.header = {
      visible: false,
      type: MESSAGE_INTERACTIVE_HEADER_BUTTON_TYPE[0].id,
      url: '',
      content: '',
      mimeType: '',
    }
    this.body = ''
    this.footer = ''
    this.action = {
      buttonTitle: $t('Ver todas las opciones'),
      sections: [],
      buttons: [],
    }
    this.characters = 0
    this.maxCharacters = 1024
    this.chunks = 1
  }

  loadData (data) {
    if (data) {
      this.name = data.name
      this.type = data.type
      this.header = data.header
      this.body = data.body
      this.footer = data.footer
      this.action = data.action
    }
  }
}
