import EventBus from '@/util/EventBus.js'
import getEnv from '@/util/env'
import { MESSAGE_INTERACTIVE_TYPE } from '@/models/whatsapp/enums/MessageInteractiveType'
import { MESSAGE_INTERACTIVE_HEADER_BUTTON_TYPE, MESSAGE_INTERACTIVE_HEADER_LIST_TYPE, MESSAGE_INTERACTIVE_HEADER } from '@/models/whatsapp/enums/MessageInteractiveHeaderType'
import EmojiPickerInput from '@/components/EmojiPickerInput/EmojiPickerInput.vue'
import MessageInteractiveButton from '@/models/whatsapp/MessageInteractiveButton'
import MessageInteractiveList from '@/models/whatsapp/MessageInteractiveList'
import MessageInteractiveListSection from '@/models/whatsapp/MessageInteractiveListSection'
import HeadboardOption from '../../../models/whatsapp-editor/HeadBoardOption'
import multimediaService from '../../../services/multimedia.service'
import TextFormatting from '@/components/TextFormatting/TextFormatting.vue'

export default {
  name: 'MessageInteractive',
  props: {
    isInboxConfigComponent: {
      type: Boolean,
      default: false,
      required: false,
    },
    hasAutoAnswer: {
      type: Boolean | Number,
      required: true,
    },
    totalRecipients: {
      type: Number,
      required: true,
    },
    fieldsAsArray: {
      type: Array,
      required: true,
    },
    messageInteractive: {
      type: Object,
      required: true,
    },
  },
  components: {
    EmojiPickerInput,
    TextFormatting,
  },
  data: function () {
    return {
      messageInteractiveTypes: MESSAGE_INTERACTIVE_TYPE,
      messageInteractiveHeaderTypes: MESSAGE_INTERACTIVE_HEADER_BUTTON_TYPE,
      showEmoji: true,
      chunkCountChange: false,
      isSelectingFile: false,
    }
  },
  created () {},
  mounted () {
    EventBus.$on('multimediaSelected', (url, type, fileId, nameTemplate, fileInfo) => {
      this.selectFile(url, fileInfo)
    })
    setTimeout(() => {
      this.$refs.interactiveMessageTextFormatting.setContext(this, this.$refs.messageInput.$el.querySelector('textarea'))
    })

    if (this.isInboxConfigComponent) {
      this.messageInteractive.type = this.messageInteractiveTypes[1].id
      this.changeInteractiveType()
      this.createAction(this.messageInteractive.type)
      this.messageInteractive.action.buttonTitle = this.$t('Envía tu valoración')

      const stars = ['★☆☆☆☆', '★★☆☆☆', '★★★☆☆', '★★★★☆', '★★★★★']
      stars.forEach((optionStarsText, index) => {
        const option = new MessageInteractiveListSection()
        option.title = optionStarsText
        option.identifier = 'ratin_star_idenfientifier_' + (index + 1)
        this.messageInteractive.action.sections[0].rows.push(option)
      })
    }
  },
  watch: {
    'messageInteractive.type': {
      handler () {
        if (this.messageInteractive.type === this.messageInteractiveTypes[0].id) {
          this.messageInteractiveHeaderTypes = MESSAGE_INTERACTIVE_HEADER_BUTTON_TYPE
        } else {
          this.messageInteractive.header.type = MESSAGE_INTERACTIVE_HEADER.TEXT
          this.messageInteractiveHeaderTypes = MESSAGE_INTERACTIVE_HEADER_LIST_TYPE
        }
      },
      deep: true,
    },
  },
  computed: {
    countMessages () {
      if (!this.messageInteractive.body) return 0
      return this.messageInteractive.body.length > 0 ? this.messageInteractive.body.length : 0
    },
    isButtonAction () {
      if (this.messageInteractive.type === this.messageInteractiveTypes[0].id) {
        return true
      }

      return false
    },
    isButtonEmpty () {
      if (
        this.messageInteractive.type === this.messageInteractiveTypes[0].id &&
        this.messageInteractive.action.buttons.length === 0
      ) {
        return true
      }

      return false
    },
    isListAction () {
      if (this.messageInteractive.type === this.messageInteractiveTypes[1].id) {
        return true
      }

      return false
    },
    isLocationAction () {
      if (this.messageInteractive.type === this.messageInteractiveTypes[2].id) {
        return true
      }

      return false
    },
    isListEmpty () {
      if (
        this.messageInteractive.type === this.messageInteractiveTypes[1].id &&
        this.messageInteractive.action.sections.length === 0
      ) {
        return true
      }

      return false
    },
    isImageHeader () {
      return this.messageInteractive.header.type === MESSAGE_INTERACTIVE_HEADER.IMAGE
    },
    isTextHeader () {
      return this.messageInteractive.header.type === MESSAGE_INTERACTIVE_HEADER.TEXT
    },
    isVideoHeader () {
      return this.messageInteractive.header.type === MESSAGE_INTERACTIVE_HEADER.VIDEO
    },
    isDocumentHeader () {
      return this.messageInteractive.header.type === MESSAGE_INTERACTIVE_HEADER.DOCUMENT
    },
  },
  methods: {
    trimMessageBody () {
      this.messageInteractive.body = this.messageInteractive.body.trim()
    },
    createAction (type) {
      if (type === this.messageInteractiveTypes[0].id) {
        if (!this.validateCreateAction()) {
          EventBus.$emit('showAlert', 'danger', this.$t('El limite de botones son 3 elementos'))
          return false
        }
        this.messageInteractive.action.buttons.push(new MessageInteractiveButton())
      }

      if (!this.validateMaxRows(this.messageInteractive.action.sections)) {
        EventBus.$emit('showAlert', 'danger', this.$t('Has alcanzado el número máximo de opciones permitidas (10)'))
        return
      }

      if (type === this.messageInteractiveTypes[1].id) {
        this.messageInteractive.action.sections.push(new MessageInteractiveList())
      }
    },
    removeButton (index) {
      this.messageInteractive.action.buttons.splice(index, 1)
    },
    validateCreateAction () {
      return this.messageInteractive.action.buttons.length < 3
    },
    validateMaxRows (sections, maxRows = 10) {
      let totalRows = 0
      sections.forEach(section => {
        totalRows += section.rows.length
      })

      return totalRows < maxRows
    },
    createListSection (index) {
      if (!this.validateMaxRows(this.messageInteractive.action.sections)) {
        EventBus.$emit('showAlert', 'danger', this.$t('Has alcanzado el número máximo de opciones permitidas (10)'))
        return
      }
      this.messageInteractive.action.sections[index].rows.push(new MessageInteractiveListSection())
    },
    removeListSection (indexSection) {
      this.messageInteractive.action.sections.splice(indexSection, 1)
    },
    removeListSectionOption (indexSection, indexOption) {
      this.messageInteractive.action.sections[indexSection].rows.splice(indexOption, 1)
    },
    showMultimedia (currentRootDirectory) {
      this.isSelectingFile = true
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },
    async selectFile (url, fileInfo) {
      if (!this.isSelectingFile) {
        return
      }
      this.isSelectingFile = false

      const [simpleType, tooBig, sizeTooBig] = HeadboardOption.fileType(fileInfo)

      if (simpleType === 'audio') {
        // Translate wav to mp3
        await multimediaService.getMp3FromAudio({
          filename: fileInfo.name + '.' + fileInfo.extension,
        })
          .then(response => {
            url = response.url
            fileInfo = response.fileInfo
          })
      }

      if (tooBig) {
        EventBus.$emit('showAlert', 'danger', this.$t('Has elegido un fichero demasiado grande. El tamaño máximo para este tipo de fichero es ') + sizeTooBig + 'MB.')
      } else {
        if (!simpleType?.length) {
          EventBus.$emit('showAlert', 'danger', this.$t('Este tipo de fichero no puede ser enviado a través de Whatsapp'))
        } else {
          this.messageInteractive.header.url = `${getEnv('VUE_APP_ASSETS_URL')}${url}`
          this.messageInteractive.header.mimeType = fileInfo.mimeType
        }
      }
    },
    changeInteractiveType () {
      this.messageInteractive.action = {
        buttonTitle: this.$t('Ver todas las opciones'),
        sections: [],
        buttons: [],
      }
      EventBus.$emit('changeInteractiveType')
    },
    changeHeadboardOption () {
      this.deleteHeadboard()
    },
    changeHeaderVisible () {
      this.deleteHeadboard()
    },
    deleteHeadboard () {
      this.messageInteractive.header.url = ''
      this.messageInteractive.header.content = ''
      this.messageInteractive.header.mimeType = ''
    },
    addEmojiToMessage (emoji) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.messageInteractive.body = before + emoji + after
    },
    addFieldToMessage (item, type, index) {
      if (item !== null) {
        let fieldName = item
        if (type === 'field') {
          fieldName = '{' + item + '}'
        }
        this.insertFieldAtCursor(fieldName, type, index)
      }
    },
    insertFieldAtCursor (myValue, type, index) {
      const textarea = this.$refs.messageInput.$refs.input
      const sentence = textarea.value
      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.messageInteractive.body = before + myValue + after
    },
    addFormatText (type) {
      const message = this.$refs.messageInput.$el.querySelector('textarea')
      const desde = message.selectionStart
      const hasta = message.selectionEnd
      const textSelected = message.value.substring(desde, hasta)
      const text = message.value

      switch (type) {
        case 'strong':
        this.addHtmlTag(text, desde, hasta, textSelected, '*', '*')
        break
      case 'italic':
        this.addHtmlTag(text, desde, hasta, textSelected, '_', '_')
        break
      case 'strike':
        this.addHtmlTag(text, desde, hasta, textSelected, '~', '~')
        break
      case 'monospace':
        this.addHtmlTag(text, desde, hasta, textSelected, '```', '```')
      }
    },
    addHtmlTag (text, desde, hasta, textSelected, tagOpen, tagClose) {
      if (textSelected.length > 0) {
        const replaceText = tagOpen + '' + textSelected + '' + tagClose
        this.messageInteractive.body = text.substring(0, desde) + replaceText + text.substring(hasta)
      }
    },
    openAiAssistant () {
      EventBus.$emit('AutoAnswerAiAssistant:open')
    },
  },
}
