
import { Service } from '@/services/service'

const PATH = 'whatsapp/contacts/'

class ContactsService extends Service {
  getData (params) {
    return this.get(PATH + 'contacts-data', params)
  }

  save (params) {
    return this.post(PATH + 'save', params)
  }

  delete (params) {
    return this.post(PATH + 'delete', params)
  }

  markAsRead (params) {
    return this.post(PATH + 'mark-as-read', params)
  }

  markAsUnread (params) {
    return this.post(PATH + 'mark-as-unread', params)
  }

  executeAction (params) {
    return this.post(PATH + 'execute-action', params)
  }

  getContactCountriesByUserMsisdn (params) {
    return this.post(PATH + 'contact-countries-by-user-msisdn', params)
  }
}

export default new ContactsService()
