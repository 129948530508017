export default {
  props: {
    saveBarProperties: {
      type: Object,
    },
    showAutoSave: {
      type: Boolean,
      default: true,
    },
  },
  name: 'SaveBar',
  components: {},
  data: function () {
    return { }
  },
  methods: {
    save () {
      if (this.saveBarProperties.autoSave) {
        this.saveBarProperties.autoSaveInterval = setInterval(() => {
          if (!this.saveBarProperties.invalid) {
            this.$emit('save')
          }
        }, this.saveBarProperties.autoSaveIntervalMiliseconds)
      } else {
        clearInterval(this.saveBarProperties.autoSaveInterval)
      }
    },
    emitSave () {
      this.saveBarProperties.loadingSave = true
      this.$emit('save')
    },
    showPreview () {
      this.$emit('show-preview')
    },
  },
  created () {
  },
  mounted () {
    this.save()
  },
}
