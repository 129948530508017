export default class MessageInteractiveListSection {
  title
  description
  type
  identifier

  constructor () {
    this.title = ''
    this.description = ''
    this.type = 'list_option'
  }
}
