import $t from '../../util/Translator.js'
import { Item } from './Item.js'

export class Question extends Item {
    question = ''
    placeholder =''
    required = false
    unsubscribePage = null
    unsubscribeText = null
    unsubscribeLinkLanguage = null
    defaultUnsubscribeText = null

    constructor (questionType) {
        super()
        this.type = questionType
        this.itemType = Item.ITEM_TYPE_QUESTION
    }

    static createFromObject (object) {
        return Question.createWithObjecValues(object)
    }

    static createWithObjecValues (object) {
        const newQuestion = new Question(object.type)
        newQuestion.question = object.question
        newQuestion.value = object.value || null
        newQuestion.required = object.required
        newQuestion.unsubscribePage = object.unsubscribePage || null
        newQuestion.unsubscribeText = object.unsubscribeText || null
        newQuestion.unsubscribeLinkLanguage = object.unsubscribeLinkLanguage || 'es'
        newQuestion.unsubscribeLinkText = object.unsubscribeLinkText || $t('Darse de baja', newQuestion.unsubscribeLinkLanguage)
        newQuestion.defaultUnsubscribeText = object.defaultUnsubscribeText || $t('En cumplimiento con lo dispuesto en el Reglamento (UE) 2016/679 de 27 de abril de 2016 (GDPR), te informamos que tus datos personales son tratados solo por nuestra empresa y cuya finalidad es remitirte, información sobre nuestros productos y servicios. Puedes ejercitar tus derechos de acceso, cancelación y oposición solicitándolo por escrito a:', newQuestion.unsubscribeLinkLanguage)
        return newQuestion
    }
}
