export class CampaignType {
  // { id: number, name: string, subtypes:{ [index: string]: string } }
  static automatic =
  {
    id: 2,
    name: 'automatic',
    subtypes: {
      meet: 'meet',
      birthday: 'birthday',
    },
  }

  // { id: number, name: string, subtypes:{ [index: string]: string } }
  static basic = {
    id: 1,
    name: 'basic',
    subtypes: {
      basic: 'basic',
    },
  }
}
