import { Service } from './service'

const PATH = 'unsubscribe/'

class UnsuscribeService extends Service {
    save (params) {
        return this.post(PATH + 'save-or-update', params)
    }

    getPage (params) {
        return this.post(PATH + 'get-page', params)
    }

    preview (params) {
        return this.post(PATH + 'preview', params)
    }

    newPage (params) {
        return this.post(PATH + 'new-page', params)
    }

    getPagesOptions (params) {
        return this.post(PATH + 'pages-options-by-type', params)
    }

    removePage (params) {
        return this.post(PATH + 'remove-page', params)
    }

    getUnsubscribePages () {
        return this.get(PATH + 'unsubscribe-pages')
    }
}

export default new UnsuscribeService()
