export class TrackingUrl {
    id = null
    name = ''
    url = ''
    previewTitle = ''
    previewDescription = ''
    previewUrl = ''

    setData (data) {
      this.id = data.id
      this.name = data.name
      this.url = data.url
      this.previewTitle = data.preview_title
      this.previewDescription = data.preview_description
      this.previewUrl = data.preview_url
    }
}
