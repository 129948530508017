import { Service } from '.././service'
const PATH = 'web-chat/contact'

class ContactService extends Service {
  saveContact (params = {}) {
    return this.post(PATH + '/save', params)
  }

  simpleSave (params = {}) {
    return this.post(PATH + '/simple-save', params)
  }

  getData (params = {}) {
    return this.get(PATH + '/contacts-data', params)
  }

  deleteContact (params = {}) {
    return this.post(PATH + '/remove', params)
  }

  getCountriesFromContacts () {
    return this.get(PATH + '/countries-from-contacts')
  }

  executeAction (params) {
    return this.post(PATH + '/execute-action', params)
  }
}

export default new ContactService()

