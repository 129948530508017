import { Preview } from './Preview'

export class WhatsappPreview extends Preview {
    totalByCountry = {}
    totalParts = 0
    contactsPhone = []
    contactPreview = 0
    totalUniquePhoneList = null
    totalNewConversations = null
    totalNotExpired = null
    maxConversations = null
    loading = false

    constructor (response) {
        super()
        this.loadData(response)
    }

    loadData (response) {
        if (response) {
            this.totalRecipients = response.totalRecipients ?? this.totalRecipients
            this.sendingCost = response.sendingCost
            this.contactPreview = response.contactPreview
            this.contactsFieldsValue = response.contactsFieldsValue
            this.errors = response.errors
            this.errorsTxt = response.errorsTxt
            this.totalByCountry = response.totalByCountry
            this.totalParts = response.totalParts
            this.contactsPhone = response.contactsPhone
            this.totalUniquePhoneList = response.totalUniquePhoneList
            this.totalNewConversations = response.totalNewConversations
            this.totalNotExpired = response.totalNotExpired
            this.maxConversations = response.maxConversations
        }
    }
}
