export class ComponentsData {
  countries = {}
  countriesWithPhonePrefix = []
  defaultTimezone = ''
  groups = []
  timezones = {}

  constructor (response) {
    if (response) {
      this.countries = response.countries
      this.countriesWithPhonePrefix = response.countriesWithPhonePrefix
      this.defaultTimezone = response.defaultTimezone
      this.groups = response.groups
      this.timezones = response.timezones
    }
  }
}
