import $t from '../../util/Translator.js'


export class QuestionType {
    static QUESTION_TYPE_TEXT = 'text'
    static QUESTION_TYPE_DATE = 'date'
    static QUESTION_TYPE_TIME = 'time'
    static QUESTION_TYPE_NUMBER = 'number'
    static QUESTION_TYPE_TEXTAREA = 'textarea'
    static QUESTION_TYPE_DROPDOWN = 'dropdown'
    static QUESTION_TYPE_CHECKBOX = 'checkbox'
    static QUESTION_TYPE_RADIO = 'radio'
    static QUESTION_TYPE_HIDDEN = 'hidden'
    static QUESTION_TYPE_CANCEL_SERVICE = 'cancel-service'

    getQuestionTypes () {
        return [
            { value: QuestionType.QUESTION_TYPE_TEXT, text: $t('Respuesta corta'), icon: 'fas fa-align-left' },
            { value: QuestionType.QUESTION_TYPE_TEXTAREA, text: $t('Párrafo'), icon: 'fas fa-align-justify' },
            { value: QuestionType.QUESTION_TYPE_NUMBER, text: $t('Número'), icon: 'fas fa-list-ol' },
            { value: QuestionType.QUESTION_TYPE_RADIO, text: $t('Selección única'), icon: 'fa fa-check-circle' },
            { value: QuestionType.QUESTION_TYPE_CHECKBOX, text: $t('Selección múltiple'), icon: 'fas fa-check-square' },
            { value: QuestionType.QUESTION_TYPE_DROPDOWN, text: $t('Desplegable'), icon: 'fas fa-caret-square-down' },
            { value: QuestionType.QUESTION_TYPE_DATE, text: $t('Fecha'), icon: 'fas fa-calendar-alt' },
            { value: QuestionType.QUESTION_TYPE_TIME, text: $t('Hora'), icon: 'fas fa-clock' },
            { value: QuestionType.QUESTION_TYPE_CANCEL_SERVICE, text: $t('Cancelar servicio'), icon: 'fas fa-user-slash' },
            { value: QuestionType.QUESTION_TYPE_HIDDEN, text: $t('Campo oculto'), icon: 'fas fa-eye-slash' },
        ]
    }

    getQuestionTypeByValue (value) {
        const questionType = new QuestionType()
        const found = questionType.getQuestionTypes().filter((type) => {
            return type.value === value
          })
        return found.length > 0 ? found[0] : null
    }


    static getMultipleQuestionTypes () {
        return [
            QuestionType.QUESTION_TYPE_DROPDOWN,
            QuestionType.QUESTION_TYPE_CHECKBOX,
            QuestionType.QUESTION_TYPE_RADIO,
        ]
    }
}

