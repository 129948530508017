import ContactService from '@/services/web-chat/contact.service.js'
import EventBus from '@/util/EventBus'
import { Contact } from '@/models/web-chat/Contact.js'
import { Country } from '@/util/Country.js'
import ContactEditDialog from '@/components/WebChat/Contacts/ContactEditDialog.vue'
import CountryService from '@/services/country.service'
import ChatTagService from '@/services/web-chat/chat-tag.service'
import AiService from '@/services/ai/ai.service'
import { Agent } from '@/classes/chat/agent'

export default {
    name: 'WebChatContact',
    components: {
        ContactEditDialog,
    },
    props: {
        showFooterActions: {
            type: Boolean,
            default: true,
            required: false,
        },
        showTableActions: {
            type: Boolean,
            default: true,
            required: false,
        },
        showAgentColumn: {
            type: Boolean,
            default: true,
            required: false,
        },
        agentFrom: {
            type: Object,
            default: null,
        },
        contactSelection: {
            type: Object,
            default: () => {
                return {
                    contactIds: [],
                    allItemsSelected: false,
                    selectedUserMsisdn: '',
                    selectedAction: 1,
                    filters: {},
                }
            },
        },
        channels: {
            type: Object,
            default: () => { return { data: [] } },
        },
        selectedChannel: {
            type: Object,
            default: () => {
              return {
                channel: 'whatsapp',
              }
            },
            required: false,
          },
    },
    data: function () {
        return {
            contacts: [],
            contact: new Contact(),
            itemsPerPage: 5,
            page: 0,
            options: {},
            totalItems: 0,
            pageCount: 0,
            total: 0,
            disabledPagination: true,
            searchTerm: '',
            tableAction: null,
            Country: Country,
            timeout: null,
            debouncedInput: null,
            removeContactDialog: false,
            contactDialogCtrl: {
                show: false,
            },
            filters: {
                statuses: [],
                tags: [],
                countries: [],
                agents: [],
            },
            initFiltersHelper: '',
            drawerContactFilers: false,
            countries: [],
            tags: [],
            searchTagText: '',
            searchAgentText: '',
            agents: [],
            countriesFromContacts: [],
            selectedContacts: [],
            allItemsSelected: false,
            selectedAction: 1,
            selectedMethod: null,
            selectedAgent: null,
            loading: false,
            dialogFilters: {
                statuses: [],
                tags: [],
                closedReasons: [],
                countries: [],
                agents: [],
            },
        }
    },
    watch: {
        params: {
            handler () {
                this.getData()
            },
            deep: true,
        },
        filters: {
            handler () {
                this.getData()
                this.$emit('updated-filters', this.filters)
            },
            deep: true,
        },
        selectedContacts: {
            handler () {
                this.contactSelection.contactIds = this.selectedContacts.map((contact) => {
                    return contact.id
                })
            },
        },

    },
    methods: {
        emitSelectedChannel () {
            this.$emit('change-selected-channel')
        },
        cancelFilters () {
            this.dialogFilters = JSON.parse(this.initFiltersHelper)
            this.filters = JSON.parse(this.initFiltersHelper)
            this.drawerContactFilers = false
        },
        applyFilters () {
            this.filters = JSON.parse(JSON.stringify(this.dialogFilters))
            this.drawerContactFilers = false
        },
        selectPage () {
            if (this.selectedContacts.length) {
                this.deselectAll()
            } else {
                this.selectedContacts = [...this.contacts]
            }
        },
        deselectOne () {
            this.allItemsSelected = false
            this.selectedAction = 1
            this.contactSelection.selectedAction = 1
            this.contactSelection.allItemsSelected = false
        },
        selectAll () {
            this.allItemsSelected = true
            this.selectedAction = 3
            this.contactSelection.selectedAction = 3
            this.contactSelection.allItemsSelected = true
        },
        deselectAll () {
            this.allItemsSelected = false
            this.selectedAction = 1
            this.contactSelection.selectedAction = 1
            this.selectedContacts = []
            this.contactSelection.allItemsSelected = false
            this.contactSelection.contactIds = []
        },
        executeAction () {
            ContactService.executeAction({
                selectedMethod: this.selectedMethod,
                selectedAction: this.selectedAction,
                contactIds: this.selectedContactIds,
                agent: this.selectedAgent,
                filters: this.filters,
            })
                .then((res) => {
                    this.getData()
                    EventBus.$emit('showAlert', 'success', this.$t('Se han reasignado los contactos'))
                }, () => {
                    EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al reasignar los contactos'))
                })
        },
        removeAgent (item) {
            console.log(item)
            this.filters.agents = this.filters.agents.filter(agent => { return agent.id !== item.id })
        },
        removeTag (item) {
            this.filters.tags = this.filters.tags.filter(tagId => { return tagId !== item.id })
        },
        changedAgentFilter () {
            this.searchTagText = ''
        },
        changedTag () {
            this.searchTagText = ''
        },
        allTags () {
            ChatTagService.allTags().then((response) => {
                this.tags = response
            })
        },
        getAllCountries () {
            CountryService
                .getAllCountries()
                .then(
                    (response) => {
                        this.countries = response.map((country) => {
                            return {
                                value: country.iso_code_2,
                                text: `${country.name} +(${country.prefix})`,
                            }
                        })
                    },
                    () => {},
                )
        },

        openFilters () {
            this.drawerContactFilers = true
        },

        resetFilters () {
            this.filters = JSON.parse(this.initFiltersHelper)
        },

        prepareEditContact (item) {
            this.contact.loadData(item)
            this.contactDialogCtrl.show = true
        },
        remove () {
            ContactService.deleteContact({ id: this.contact.id }).then((response) => {
                this.tableAction = 'DELETE'
                this.removeContactDialog = false
                this.getData()
                EventBus.$emit('showAlert', 'success', this.$t('El contacto se ha eliminado correctamente.').toString())
            })
        },
        prepareRemove (item) {
            this.contact.loadData(item)
            this.removeContactDialog = true
        },
        fullName (item) {
            return (item.firstname ?? '') + ' ' + (item.lastname ?? '')
        },
        contactLetters (user) {
            return (user.firstname?.charAt(0).toUpperCase() ?? '') + (user.lastname?.charAt(0).toUpperCase() ?? '')
        },
        startsWithEmoji (user) {
            const emojiRegex = /^\p{Extended_Pictographic}/u
            return emojiRegex.test(user.firstname)
        },
        initTable () {
            this.options.itemsPerPage = 5
            this.options.page = 1
        },
        getParams () {
            const page = this.tableAction === 'DELETE' && this.contacts.length === 1 && this.options.page > 1
                ? this.options.page - 1
                : this.options.page
            this.tableAction = null

            const params = {
                page: page,
                perPage: this.options.itemsPerPage,
                searchTerm: this.searchTerm,
                timeFilter: this.timeFilter,
                selectedUserMsisdn: this.selectedUserMsisdn,
                filters: this.filters,
                agentIdFrom: this.agentFrom ? this.agentFrom.id : null,
            }

            for (let i = 0; i < this.options.sortBy.length; i++) {
                params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
                params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
                    ? 'asc'
                    : 'desc'
            }
            return params
        },
        getData () {
            EventBus.$emit('showLoading', true)
            const data = this.getParams()
            this.disabledPagination = true
            ContactService.getData(data).then((response) => {
                this.contacts = response.data
                this.itemsPerPage = parseInt(response.per_page)
                this.page = response.current_page
                this.totalItems = response.total
                this.disabledPagination = false
                EventBus.$emit('showLoading', false)
            })
        },
    },
    computed: {
        pageSelected () {
            return this.selectedContacts.length && this.selectedContacts.length < this.totalItems && !this.allItemsSelected
        },
        selectedContactIds () {
            return this.selectedContacts.map((contact) => contact.id)
        },
        methods () {
            return [
                { value: 'set_agent', text: this.$t('Asignar a agente').toString() },
            ]
        },
        actionOptions () {
            return [
                { text: this.$t('Seleccionados').toString(), value: 1 },
                { text: this.$t('No seleccionados').toString(), value: 2 },
                { text: this.$t('Todos (Sin tener en cuenta la paginación)').toString(), value: 3 },
            ]
        },
        countriesFromContactsFiltered () {
            return this.countries.filter((country) => {
                return this.countriesFromContacts.includes(country.value)
            })
        },

        hasFilters () {
            return JSON.stringify(this.filters) !== this.initFiltersHelper
        },

        contactStatuses () {
            return [
                { value: 0, text: this.$t('Cerrado') },
                { value: 1, text: this.$t('Abierto') },
            ]
        },
        params (nv) {
            return {
                ...this.options,
                query: this.searchTerm,
                time: this.timeFilter,
                selectedUserMsisdn: this.selectedUserMsisdn,
            }
        },
        searchTermHelper: {
            get: function () {
                return this.debouncedInput
            },
            set: function (val) {
                this.debouncedInput = val
                if (this.timeout !== null) {
                    clearTimeout(this.timeout)
                }
                this.timeout = setTimeout(() => {
                    this.searchTerm = val
                }, 700)
            },
        },
        headers () {
            const headers = [
                {
                    value: 'avatar',
                    sortable: false,
                },
                {
                    text: this.$t('Nombre'),
                    value: 'firstname',
                },
                {
                    text: this.$t('Teléfono'),
                    value: 'phone',
                },
                {
                    text: this.$t('Email'),
                    value: 'email',
                },
                {
                    text: this.$t('Estado'),
                    value: 'status',
                },
            ]


            if (this.showAgentColumn) {
                headers.push({
                    text: this.$t('Agente'),
                    value: 'agent_id',
                    align: 'center',
                    cellClass: 'pb-1',
                    sortable: false,
                })
            }

            headers.push({
                text: this.$t('País'),
                value: 'country_iso',
            })

            if (this.showTableActions) {
                headers.push({
                    text: this.$t('Acciones'),
                    value: 'actions',
                    sortable: false,
                })
            }
            return headers
        },
    },
    created () {
        this.initFiltersHelper = JSON.stringify(this.filters)
        this.getAllCountries()
        this.allTags()
        AiService.getAssistantsSimpleList().then((res) => {
            res.forEach(agent => {
                const agentObject = new Agent(agent)
                this.agents.push(agentObject)
            })
        })

        ContactService.getCountriesFromContacts().then((countriesIsos) => {
            this.countriesFromContacts = countriesIsos
        })
        this.contactSelection.contactIds = []
        this.contactSelection.allItemsSelected = false
        this.$emit('updated-filters', this.filters)
    },
}
