import { Question } from './Question'

export class QuestionMultiple extends Question {
    options = []
    constructor (questionType) {
        super()
        this.type = questionType
    }

    static createWithObjecValues (object) {
        const newQuestion = new QuestionMultiple(object.type)
        newQuestion.question = object.question
        newQuestion.required = object.required
        newQuestion.options = object.options
        return newQuestion
    }
}
