import { Header } from './Header.js'
import { Question } from './Question.js'
import { QuestionMultiple } from './QuestionMultiple'
import { QuestionType } from './QuestionType.js'
import { Item } from './Item.js'

export class Form {
    properties = {
        backgroundColor: '#ffffff',
        primaryColor: '',
        buttonText: '',
        buttonTextColor: '',
        thanksText: '',
    }

    header = new Header()

    sections = [
        { items: [] },
    ]

    addQuestion (type) {
        const multipleQuestionTypes = QuestionType.getMultipleQuestionTypes()
        const question = multipleQuestionTypes.includes(type) ? new QuestionMultiple(type) : new Question(type)
        this.sections[0].items.push(question)
    }

    loadForm (form) {
        this.properties.backgroundColor = form.properties.backgroundColor
        this.properties.primaryColor = form.properties.primaryColor
        this.properties.buttonText = form.properties.buttonText
        this.properties.buttonTextColor = form.properties.buttonTextColor
        this.sections = this.parseSections(form.sections)
        this.header = form.header
    }

    parseSections (sections) {
        this.sections = []
        const newSections = []
        sections.forEach(section => {
            newSections.push(this.parseSection(section))
        })
        return newSections
    }

    parseSection (section) {
        const newSection = {
            items: [],
        }
        section.items.forEach(item => {
            newSection.items.push(this.parseItem(item))
        })
        return newSection
    }

    parseItem (item) {
        let newItem = null
        switch (item.itemType) {
            case Item.ITEM_TYPE_HEADER:
                newItem = Header.createFromObject(item)
                break
            case Item.ITEM_TYPE_QUESTION:
                newItem = QuestionType.getMultipleQuestionTypes().includes(item.type) ? QuestionMultiple.createWithObjecValues(item) : Question.createWithObjecValues(item)
                break
        }
        return newItem
    }

    hasCancelServiceItem () {
        let hasCancelService = false
        this.sections.forEach(section => {
            const items = section.items.filter(item => {
                return item.type === 'cancel-service'
            })
            if (items.length > 0) {
                hasCancelService = true
            }
        })
        return hasCancelService
    }
}
