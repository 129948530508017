export default {
  name: 'UrlPreview',
  props: {
    previewUrl: { type: String, require: true },
  },
  data: function () {
    return {
      viewSelected: 'email',
      viewTypes: [
        { key: 'email', name: 'Email' },
        { key: 'web', name: 'WEB' },
      ],
      sizeSelected: 'desktop',
      deviceSizes: [
        { key: 'mobile', name: this.$t('Móvil'), icon: 'fas fa-mobile-alt' },
        { key: 'tablet', name: this.$t('Tablet'), icon: 'fas fa-tablet-alt' },
        { key: 'desktop', name: this.$t('Navegador'), icon: 'fas fa-desktop' },
      ],
    }
  },
  methods: {
    changeView (type) {
    },
  },
}
