export default {
  name: 'MeetOptions',
  props: ['campaign', 'timezones', 'defaultTimezone'],
  data: function () {
    return {
      timezoneSelector: false,
      conditionValues: [
        { value: '-7', text: '1 ' + this.$t('semana antes') },
        { value: '-6', text: '6 ' + this.$t('días antes') },
        { value: '-5', text: '5 ' + this.$t('días antes') },
        { value: '-4', text: '4 ' + this.$t('días antes') },
        { value: '-3', text: '3 ' + this.$t('días antes') },
        { value: '-2', text: '2 ' + this.$t('días antes') },
        { value: '-1', text: '1 ' + this.$t('día antes') },
        { value: '0', text: this.$t('El mismo día') },
        { value: '1', text: '1 ' + this.$t('día después') },
        { value: '2', text: '2 ' + this.$t('días después') },
        { value: '3', text: '3 ' + this.$t('días después') },
        { value: '4', text: '4 ' + this.$t('días después') },
        { value: '5', text: '5 ' + this.$t('días después') },
        { value: '6', text: '6 ' + this.$t('días después') },
        { value: '7', text: '1 ' + this.$t('semana después') },
      ],
      sendingTime: [],
    }
  },
  methods: {
    changeTimezone () {
      this.campaign.sendings[0].automaticConfig.conditions.conditionsValues[2].conditionVal = this.campaign.sendings[0].sendingTimezone
      this.campaign.sendings[0].automaticConfig.conditions.conditionsValues[2].description = this.timezones[this.campaign.sendings[0].sendingTimezone]
    },
    showTimezoneSelector () {
      this.timezoneSelector = true
    },
  },
  computed: {
    timezonesList: function () {
      const list = []
      for (const timezone in this.timezones) {
        list.push({ value: timezone, text: this.timezones[timezone] })
      }
      return list
    },
  },
  created () {
    this.campaign.sendings[0].automaticConfig.conditions.conditionsValues[2].conditionVal = this.campaign.sendings[0].sendingTimezone
    this.campaign.sendings[0].automaticConfig.conditions.conditionsValues[2].description = this.timezones[this.campaign.sendings[0].sendingTimezone]
    this.campaign.sendings[0].automaticConfig.conditions.conditionsValues[1].conditionVal = 'm-d-y'
    let time1 = 0
    let time2 = 0
    for (let i = 0; i < 24; i++) {
      if (i < 10) {
        time1 = ('0' + i + ':00').toString()
        time2 = ('0' + i + ':30').toString()
      } else {
        time1 = (i + ':00').toString()
        time2 = (i + ':30').toString()
      }
      this.sendingTime.push({ value: time1 + ':00', text: time1 })
      this.sendingTime.push({ value: time2 + ':00', text: time2 })
    }
  },
}
