export default class MessageInteractiveButton {
  id
  title
  type

  constructor () {
    this.id = ''
    this.title = ''
    this.type = 'quick_reply'
  }
}
