export default class AutoAnswerType {
  options
  text
  interactive

  constructor () {
    this.options = [
      { text: 'Solo texto', value: 'text' },
      { text: 'Mensajes interactivos', value: 'interactive' },
    ]
    this.text = 'text'
    this.interactive = 'interactive'
  }
}
