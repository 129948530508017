import { Country } from '@/util/Country.js'
import AccountService from '@/services/account.service'

export default {
  name: 'ModalResumeSend',
  props: {
    dialogCtrl: {
      type: Object,
      default: {
        show: false,
      },
    },
    campaign: {
      require: false,
      type: Object,
    },
  },
  data () {
    return {
      Country: Country,
      balanceAlert: {},
    }
  },
  methods: {
    emitAccepted () {
      this.$emit('accepted')
    },
    emitSaveAndBalance () {
      this.$emit('saveAndBalance')
    },
    setBalanceAlert () {
      AccountService.getBalance().then((data) => {
        this.balanceAlert = data
      })
    },
  },
  computed: {
    categoryName () {
      const categories = { 1: this.$t('Utilidad'), 2: this.$t('Marketing'), 3: this.$t('Autenticación') }
      return categories[this.campaign.sendings[0].channel.template.template_whatsapp_category_id]
    },
    balance () {
      return this.$store.state.balance
    },
    calculatedBalance () {
      return this.balance.amount
    },
    hasNotEnoughBalance () {
      return this.campaign.campaignCosts ? this.campaign.campaignCosts.totalCost > this.calculatedBalance : false
    },
    hasNotEnoughBalanceClass () {
      return this.campaign.campaignCosts && this.campaign.campaignCosts.totalCost > this.calculatedBalance ? 'not-enough-balance' : ''
    },
  },
  created () {
    this.setBalanceAlert()
  },
}
