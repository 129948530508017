
import { Service } from '@/services/service'

const PATH = 'whatsapp/tags/'

class UserInboxTagService extends Service {
  getTagsData (params) {
    return this.get(PATH + 'tags-data', params)
  }

  allTags (params = {}) {
    return this.get(PATH + 'all-tags', params)
  }

  save (params) {
    return this.post(PATH + 'save', params)
  }

  delete (params) {
    return this.post(PATH + 'delete', params)
  }

  countTagUse (params) {
    return this.post(PATH + 'count-tag-use', params)
  }
}

export default new UserInboxTagService()
