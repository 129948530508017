import EventBus from '@/util/EventBus.js'
export default {
  name: 'CertifiedSmsModal',
  data: function () {
    return {
      show: false,
    }
  },
  methods: {
    confirmCertifiedSms () {
      this.$emit('confirmCertifiedSms')
    },

    cancelCertifiedSms () {
      this.$emit('cancelCertifiedSms')
      this.show = false
    },
  },
  mounted () {
    const that = this
    EventBus.$on('showModalCertifiedSms', function () {
      that.show = true
    })
    EventBus.$on('hideModalCertifiedSms', function () {
      that.show = false
    })
  },
}
