export class Contact {
  id = null
  firstname = ''
  lastname = ''
  phone = ''
  email = ''
  countryIso = ''
  tags = []

  loadData (data) {
    this.id = data.id
    this.firstname = data.firstname
    this.lastname = data.lastname
    this.phone = data.phone
    this.email = data.email
    this.countryIso = data.country_iso
    this.tags = data.tags
  }
}
