import { Item } from './Item.js'

export class Header extends Item {
    title = '';
    description = '';
    imageUrl = '';
    constructor () {
        super()
        this.itemType = Item.ITEM_TYPE_HEADER
    }

    static createFromObject (object) {
        const header = new Header()
        header.title = object.title
        header.description = object.description
        header.imageUrl = object.imageUrl
        return header
    }
}
