

export default {
  name: 'VariableSubstitutionDialog',
  props: {
    showModal: {
      type: Object,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    customFields: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      dltVarFields: [],
    }
  },
  methods: {
    closeModal () {
      this.showModal.show = false
    },
    acceptModal () {
      this.$emit('accept', this.substituteVarsMessage())
      this.closeModal()
    },
    substituteVarsMessage (display = false) {
      let idx = 0
      return this.message.replace(/\{COL\+}/g, (match) => {
          let substitution = match
          if (this.dltVarFields[idx]?.customField) {
            const field = this.dltVarFields[idx]?.customField
            const divided = field.split('COL')
            substitution = divided.length === 2 ? '{COL' + divided[1] + '}' : '{' + field + '}'
            // console.log(substitution)
            if (display) {
              substitution = `<b>${substitution}</b>`
            }
          }
          idx++
          return substitution
        })
    },
    setDltVarFields () {
      this.dltVarFields = (this.message.match(/\{COL\+}/g) || [])
        .map((field) => ({
          field,
          customField: null,
        }))
    },
  },
  computed: {
    currentMessage () {
      return this.substituteVarsMessage(true)
    },
  },
  mounted () {
    this.setDltVarFields()
  },
}
