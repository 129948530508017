export const MESSAGE_INTERACTIVE_TYPE = [
  {
    id: 0,
    name: 'Botón',
    slug: 'button',
  },
  {
    id: 1,
    name: 'Lista',
    slug: 'list',
  },
  {
    id: 2,
    name: 'Ubicación',
    slug: 'location_request_message',
  },
]

export const MESSAGE_INTERACTIVE_TYPES = {
  BUTTON: 0,
  LIST: 1,
  LOCATION: 2,
}
