export class SaveBarProperties {
  templateName
  autoSave = false
  autoSaveInterval = null
  preview = true
  autoSaveIntervalMiliseconds = 5000
  loaderTime = 1000
  loadingSave = false
  invalid = false
  showPreviewButton = true
  setSavedLoader () {
    setTimeout(() => { this.loadingSave = false }, this.loaderTime)
  }
}
