export default class MessageInteractiveList {
  title
  type
  rows

  constructor () {
    this.title = ''
    this.type = 'list'
    this.rows = []
  }
}
