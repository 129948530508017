export default class CampaignCosts {
  totalMessages
  totalParts
  totalByCountry = []
  totalCost

  constructor () {
    this.totalMessages = 0
    this.totalParts = 0
    this.totalByCountry = []
    this.totalCost = 0
  }

  load (campaignCosts) {
    this.totalMessages = campaignCosts.totalMessages || 0
    this.totalParts = campaignCosts.totalParts || 0
    this.totalByCountry = campaignCosts.totalByCountry || []
    this.totalCost = campaignCosts.totalCost || 0
  }
}
